// 获取列表
// const getListURL = `${API_CONFIG.baseURL}smsAction!dailyList.action` // 旧接口
const getListURL = `${API_CONFIG.butlerBaseURL}sms/daily/pageList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}sms/export/daily/statistic`

export {
  getListURL,
  exportListURL
}
