<template>
  <div class="DailySummaryList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
    >
      <template #searchSlot>
        <v-datepicker label="发送时间段" :startTime.sync="searchParams.fdateFrom" :endTime.sync="searchParams.fdateTo"></v-datepicker>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" :width="160"></v-select2>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams, regionParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'
import { setStatusOps } from './map'

export default {
  name: 'DailySummaryList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: setStatusOps(1),
      communityParams,
      regionParams,
      searchParams: {
        fdateFrom: '',
        fdateTo: '',
        communityId: '',
        regionId: ''
      },
      headers: [
        {
          prop: 'daily',
          label: '日期'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'sendCount',
          label: '计算短信条数',
          align: 'right'
        },
        {
          prop: 'actualCount',
          label: '实际发送短信条数',
          align: 'right'
        },
        {
          prop: 'dailyMoney',
          label: '实际短信费',
          align: 'right'
        }
      ]
    }
  },
  methods: {
    edit (row) {
      this.$router.push({
        name: 'dailySummaryForm',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
